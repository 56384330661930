import React from 'react';
import css from './Details.css';
import { Amenities, Availability, BookingPanel, Equipment, Highlights, Photos, Pricing } from '../index';
import { START_DATE } from 'react-dates/constants';
import moment from 'moment';
import { array, bool, func, number, object } from "prop-types";
import { isDayBooked, rangeContainsBookedDay } from '../../utils/calendar';

const today = moment().startOf('day');
const minimumDate = today.clone().startOf('month');

class Details extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: null,
            endDate: null,
            focusedInput: START_DATE,
        };

        this.isDayBlocked = this.isDayBlocked.bind(this);
        this.isDayBooked = this.isDayBooked.bind(this);
        this.onDatesChange = this.onDatesChange.bind(this);
        this.onFocusChange = this.onFocusChange.bind(this);
    }

    isDayBlocked = day => {
        return day.isSameOrBefore(today, 'day') || this.isDayBooked(day);
    };

    isDayBooked = day => {
        return isDayBooked(day, this.props.bookedDates);
    };

    onDatesChange = ({ startDate, endDate }) => {
        // Verify that there are no blocked days within the range
        if (!rangeContainsBookedDay(startDate, endDate, this.props.bookedDates)) {
            this.setState({
                startDate,
                endDate,
            });
        }
    };

    onFocusChange(focusedInput) {
        this.setState({
            focusedInput: !focusedInput ? START_DATE : focusedInput,
        });
    }

    render() {
        const {
            bookingData,
            bookingModalIsOpen,
            calendarIsLoaded,
            handleSaveBookingData,
            handleToggleShowTerms,
            onCloseBookingModal,
            onOpenBookingModal,
            windowWidth,
        } = this.props;
        const { endDate, focusedInput, startDate } = this.state;

        return (
            <div className={css.root}>
                <div className={css.content}>
                    <Highlights />
                    <Amenities />
                    <Photos />
                    <Equipment />
                    <Pricing />
                    <Availability
                        calendarIsLoaded={calendarIsLoaded}
                        endDate={endDate}
                        focusedInput={focusedInput}
                        isDayBlocked={this.isDayBlocked}
                        isDayBooked={this.isDayBooked}
                        minimumDate={minimumDate}
                        onDatesChange={this.onDatesChange}
                        onFocusChange={this.onFocusChange}
                        onOpenBookingModal={onOpenBookingModal}
                        renderDayContents={this.renderDayContents}
                        startDate={startDate}
                        today={today}
                        windowWidth={windowWidth}
                    />
                </div>
                <div className={css.sideBar}>
                    <BookingPanel
                        bookingData={bookingData}
                        bookingModalIsOpen={bookingModalIsOpen}
                        endDate={endDate}
                        handleSaveBookingData={handleSaveBookingData}
                        handleToggleShowTerms={handleToggleShowTerms}
                        onCloseBookingModal={onCloseBookingModal}
                        onOpenBookingModal={onOpenBookingModal}
                        startDate={startDate}
                    />
                </div>
            </div>
        );
    }
}

Details.propTypes = {
    bookingData: object.isRequired,
    bookedDates: array.isRequired,
    bookingModalIsOpen: bool.isRequired,
    calendarIsLoaded: bool.isRequired,
    handleSaveBookingData: func.isRequired,
    handleToggleShowTerms: func.isRequired,
    onCloseBookingModal: func.isRequired,
    onOpenBookingModal: func.isRequired,
    windowWidth: number,
};

Details.defaultProps = {
    windowWidth: 1024,
};

export default Details;