import { string } from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import { injectIntl } from 'react-intl';
import css from './TextField.css';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

class TextField extends React.Component {
    render() {
        const { component, intl, label, placeholder, ...rest } = this.props;
        const name = rest.name;
        return (
            <Field
                className={css.root}
                {...rest}
            >
                {({ input, meta }) => {
                    const hasError = meta.error && meta.touched;
                    const hasSuccess = meta.touched && !meta.error;
                    const wrapperClasses = classNames(css.fieldWrapper, hasError ? css.hasError : null);
                    const inputClasses = classNames(css.field, hasError ? css.fieldError : null, hasSuccess ? css.fieldSuccess : null);

                    return (
                        <>
                            <label className={css.fieldLabel} htmlFor={name}>
                                {label}
                            </label>
                            <div className={wrapperClasses}>
                                {component === 'textarea'
                                    ? <textarea {...input} className={inputClasses} name={name} placeholder={placeholder} />
                                    : <input {...input} className={inputClasses} name={name} placeholder={placeholder} />}
                                {hasError && <span className={css.error}>{meta.error}</span>}
                                {hasSuccess && <span className={css.success}>
                                    <FontAwesomeIcon icon={faCheckCircle} />
                                </span>}
                            </div>
                        </>
                    )
                }}
            </Field>
        );
    }
}

TextField.propTypes = {
    name: string.isRequired,
};

export default injectIntl(TextField);