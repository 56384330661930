import React from "react";
import css from './TopBar.css';
import { Navigation } from '../index';
import classNames from 'classnames';
import config from "../../config";

class TopBar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hasScrolled: 0,
            isMobileNavigationOpen: false,
            hideNavigation: props.hideNavigation || false,
        };

        this.handleScroll = this.handleScroll.bind(this);
        this.handleToggleMobileNavigation = this.handleToggleMobileNavigation.bind(this);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll() {
        const { scrollY } = window;
        if ((scrollY === 0 && this.state.hasScrolled) || (!this.state.hasScrolled &&  scrollY > 0)) {
            this.setState({ hasScrolled: window.scrollY > 0 })
        }
    }

    handleToggleMobileNavigation() {
        this.setState({
            isMobileNavigationOpen: !this.state.isMobileNavigationOpen,
        });
    }

    render() {
        return (
            <div className={classNames(css.root, this.state.hasScrolled ? css.hasScrolled : null)}>
                <a href="/" className={css.brand}>
                    <span className={css.brandName}>{config.site.name}</span>
                </a>
                {!this.state.hideNavigation && ( 
                    <Navigation
                        config={this.props.config}
                        hasScrolled={this.state.hasScrolled}
                        isMobileNavigationOpen={this.state.isMobileNavigationOpen}
                        onToggleMobileNavigation={this.handleToggleMobileNavigation}
                    />
                )}
            </div>
        );
    }
}

export default TopBar;