import { array, string } from 'prop-types';
import React from 'react';
import { withLabels } from '../../utils/translations';
import css from './DefinitionList.css';

class DefinitionList extends React.Component {
    render() {
        const { componentName, definitions, getLabel, id } = this.props;
        return (
            <dl className={css.root}>
                {definitions.map((definition, index) => {
                    return (
                        <React.Fragment key={`${id}-${index}`}>
                            <dt className={css.type}>{getLabel(definition.type, {}, componentName)}</dt>
                            <dd className={css.description}>{definition.description}</dd>
                        </React.Fragment>
                    )
                })}
            </dl>
        );
    }
}

DefinitionList.propTypes = {
    componentName: string.isRequired,
    definitions: array,
    id: string.isRequired,
};

DefinitionList.defaultProps = {
    definitions: [],
}
;
export default withLabels(DefinitionList, 'DefinitionList');