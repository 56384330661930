import React from 'react';
import { useLabels } from '../../utils/translations';
import css from "./FeatureList.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import config from '../../config';
import classNames from 'classnames';
import { useState } from 'react';
import { useIntl } from "react-intl";

export default function FeatureList({ features, labelPrefix }) {
    const intl = useIntl();
    const getLabel = useLabels(labelPrefix, intl);
    const [isExpanded, setIsExpanded] = useState(false);
    const handleClickMore = () => {
        setIsExpanded(true);
    }
    const classes = classNames(css.root, isExpanded ? css.expanded : null)

    return (
        <div className={classes}>
            <ul className={css.list}>
                {features.map(feature => {
                    return (
                        <li className={css.item} key={feature.label}>
                            <FontAwesomeIcon
                                className={css.icon} icon={feature.icon} color={config.misc.iconColor}
                            />
                            <span className={css.label}>
                            {getLabel(feature.label, feature.values || {}, labelPrefix)}
                        </span>
                        </li>
                    );
                })}
            </ul>
            <div className={css.overlay}>
                <button className={css.more} type={'button'} onClick={handleClickMore}>
                    {getLabel('more')}
                </button>
            </div>
        </div>
    );
}
