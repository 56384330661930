import React from 'react';
import { string } from 'prop-types';
import css from './Tooltip.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';

class Tooltip extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: false,
        };

        this.timeout = null;
        this.handleMouseEnter = this.handleMouseEnter.bind(this);
        this.handleMouseLeave = this.handleMouseLeave.bind(this);
    }

    handleMouseEnter() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        this.setState({ visible: true });
    }

    handleMouseLeave() {
        this.timeout = setTimeout(() => {
            this.setState({ visible: false });
        }, 50);
    }

    render() {
        const { className, text } = this.props;
        const rootClasses = classNames(css.root, className);
        const classes = classNames(css.tooltip, this.state.visible ? css.visible : null);
        return (
            <span className={rootClasses} onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}>
                <FontAwesomeIcon icon={faInfoCircle}/>
                <span className={classes}>{text}</span>
            </span>
        )
    }
}

Tooltip.propTypes = {
    className: string,
    text: string.isRequired,
};

Tooltip.defaultProps = {
    className: null
};

export default injectIntl(Tooltip);