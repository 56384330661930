import { func } from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import { injectIntl } from 'react-intl';
import css from './CheckField.css';
import classNames from 'classnames';

class CheckField extends React.Component {
    render() {
        const { component, intl, label, onChange, placeholder, ...rest } = this.props;
        const name = rest.name;
        rest.type = 'checkbox';
        return (
            <Field
                className={css.root}
                onInputChange={(e) => console.log('Stuff changed!', e)}
                {...rest}
            >
                {({ input, meta }) => {
                    const hasError = meta.error && meta.touched;
                    const hasSuccess = meta.touched && !meta.error;
                    const inputClasses = classNames(css.field, hasError ? css.fieldError : null, hasSuccess ? css.fieldSuccess : null);

                    return (
                        <>
                            <label className={css.fieldLabel} htmlFor={name}>
                                <input
                                    {...input}
                                    id={name}
                                    className={inputClasses}
                                    onChange={e => {
                                        input.onChange(e);
                                        if (onChange) {
                                            onChange(e);
                                        }
                                    }}
                                />
                                {label}
                            </label>
                        </>
                    )
                }}
            </Field>
        );
    }
}

CheckField.propTypes = {
    onChange: func,
};

CheckField.defaultProps = {
    onChange: null,
};

export default injectIntl(CheckField);