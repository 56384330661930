import React from 'react';
import { useLabels } from '../../utils/translations';
import { useIntl } from "react-intl";
import css from './Instructions.css';
import { Section, SectionHeader } from '../index';

const componentName = 'Instructions';

export default function Instructions({ config }) {
    const intl = useIntl();
    const getLabel = useLabels(componentName, intl);

    return (
        <Section id={'instructions'}>
            <SectionHeader title="Kasutusjuhend" />
            <ol className={css.instructions}>
                <li>
                    Otsi sobiv asukoht, jälgides, et maapind oleks tasane ning piisavalt tugev kandmaks veega täidetud kümblustünni raskust.
                </li>
                <li>
                    Paigalda tugijalad ning korsten. Veendu, et see oleks ohutus kauguses süttida võivatest objektidest.
                </li>
                <li>
                    Ühenda kastmisvoolik ning võid alustada veega täitmist. Täielik täitumine võtab umbes 1h.
                    Kui kümblustünnis on vesi sees, on selle liigutamine keelatud!
                </li>
                <li>
                    Kui veetase on tõusnud kõrgemale ülemisest ahju ühendustorust võid alustada kütmist.
                    Ilma veeta on kümblustünni ahju tule tegemine keelatud!
                </li>
                <li>
                    Soovitatav veetemperatuur kümblemiseks on 36–38 °C. Aega-ajalt võiks mõlaga vett segada. 
                </li>
                <li>
                    Enne veest tühjendamist, veenduge, et kõik söed on kustunud. Tühjendades ära unusta ka ahjust vett välja lasta.
                </li>
            </ol>
        </Section>
    )
}