import { bool, func } from 'prop-types';
import React from "react";
import { withLabels } from '../../utils/translations';
import css from './Navigation.css';
import { Link } from 'react-scroll';
import config from "../../config";
import {
    faBars, faTimes
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from 'classnames';

class Navigation extends React.Component {
    constructor(props) {
        super(props);
        this.handleLinkClick = this.handleLinkClick.bind(this);
    }

    handleLinkClick = e => {
        const { isMobileNavigationOpen, onToggleMobileNavigation } = this.props;
        if (isMobileNavigationOpen) {
            onToggleMobileNavigation();
        }
        return e;
    };

    render() {
        const { getLabel, isMobileNavigationOpen, onToggleMobileNavigation, config: serviceConfig } = this.props;
        const { transitionTime, topOffset } = config.scroll;
        return (
            <div className={classNames(css.root, isMobileNavigationOpen ? css.isMobileNavigationOpen : null)}>
                <div className={css.mobileNavigationWrapper}>
                    <button className={css.mobileMenuButton} onClick={onToggleMobileNavigation}>
                        <FontAwesomeIcon icon={isMobileNavigationOpen ? faTimes : faBars}/>
                    </button>
                </div>
                <nav className={css.navigation}>
                    {serviceConfig["sections"].includes("amenities") ? (
                        <Link
                            className={css.link}
                            onClick={this.handleLinkClick}
                            to={'amenities'}
                            duration={transitionTime}
                            offset={topOffset}
                            smooth={'easeInOutQuart'}
                        >
                            <span>{getLabel('amenities')}</span>
                        </Link>
                    ) : null}
                    {serviceConfig["sections"].includes("photos") ? (
                        <Link
                            className={css.link}
                            onClick={this.handleLinkClick}
                            to={'photos'}
                            duration={transitionTime}
                            offset={topOffset}
                            smooth={'easeInOutQuart'}
                        >
                            <span>{getLabel('photos')}</span>
                        </Link>
                    ) : null}
                    {serviceConfig["sections"].includes("pricing") ? (
                        <Link
                            className={css.link}
                            onClick={this.handleLinkClick}
                            to={'pricing'}
                            duration={transitionTime}
                            offset={topOffset}
                            smooth={'easeInOutQuart'}
                        >
                            <span>{getLabel('pricing')}</span>
                        </Link>
                    ) : null}
                    {serviceConfig["sections"].includes("availability") ? (
                        <Link
                            className={css.link} onClick={this.handleLinkClick} to={'availability'}
                            duration={transitionTime} offset={topOffset}
                            smooth={'easeInOutQuart'}
                        >
                            <span>{getLabel('availability')}</span>
                        </Link>
                    ) : null}
                    <Link
                        className={css.link} onClick={this.handleLinkClick} to={'location'} duration={transitionTime}
                        offset={topOffset}
                        smooth={'easeInOutQuart'}
                    >
                        <span>{getLabel('location')}</span>
                    </Link>
                    <Link
                        className={css.link} onClick={this.handleLinkClick} to={'contact'} duration={transitionTime}
                        offset={topOffset}
                        smooth={'easeInOutQuart'}
                    >
                        <span>{getLabel('contact')}</span>
                    </Link>
                </nav>
            </div>
        );
    }
}

Navigation.propTypes = {
    isMobileNavigationOpen: bool.isRequired,
    onToggleMobileNavigation: func.isRequired,
};

Navigation.defaultProps = {
    isMobileNavigationOpen: false,
};

export default withLabels(Navigation, 'Navigation');