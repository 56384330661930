import { string } from 'prop-types';
import React from 'react';
import css from './SectionHeader.css';

class SectionHeader extends React.Component {
    render() {
        const { subTitle, title } = this.props;
        return(
            <div className={css.root}>
                <h2 className={css.title}>
                    {title}
                </h2>
                {subTitle ? (
                    <p className={css.subTitle}>
                        {subTitle}
                    </p>
                ) : null}
            </div>
        )
    }
}

SectionHeader.propTypes = {
    subTitle: string,
    title: string.isRequired,
};

SectionHeader.defaultProps = {
    subTitle: null,
};

export default SectionHeader;