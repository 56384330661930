import { faBed, faHardHat, faIdCard, faWeightHanging, faUser, faClock, faWater, faRuler } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useLabels } from '../../utils/translations';
import { useIntl } from "react-intl";
import css from './Highlights.css';
import { Section } from '../index';

const componentName = 'Highlights';
const icons = {
    "beds": faBed,
    "weight": faWeightHanging,
    "drivingLicenseRequirement": faIdCard,
    "builtYear": faHardHat,
    "group": faUser,
    "heatUp": faClock,
    "size": faWater,
    "diameter": faRuler
}

export default function Highlights({ config }) {
    const intl = useIntl();
    const getLabel = useLabels(componentName, intl);

    return (
        <Section id={'highlights'}>
            <div className={css.highlights}>
                {config["highlights"]["items"].map((item, index) => {
                    return (
                        <li className={css.highlight} key={`highlights-${index}`}>
                            <FontAwesomeIcon className={css.icon} icon={icons[item]} />
                            <span className={css.description}>
                                {getLabel(item, config["highlights"]["values"][item])}
                            </span>
                        </li>
                    )
                })}
            </div>
            {config["highlights"]["footNote"] ? (
                <p className={css.note}>
                    {getLabel(`${config["slug"]}.footNote`)}
                </p>
            ) : null}
        </Section>
    )
}