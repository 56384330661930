import React from 'react';
import { injectIntl } from 'react-intl';

export const withLabels = (Component, componentName) => {
    class WithLabelsComponent extends React.Component {
        constructor(props) {
            super(props);
            this.getLabel = this.getLabel.bind(this);
        }

        getLabel = (id, values, labelPrefix = null) => {
            const { intl } = this.props;
            return intl.formatMessage({ id: `${labelPrefix || componentName}.${id}` }, values);
        };

        render () {
            return <Component {...this.props} getLabel={this.getLabel} />
        }
    }

    return injectIntl(WithLabelsComponent);
};

export const useLabels = (componentName, intl) => {
    return (id, values, labelPrefix = null) => {
        return intl.formatMessage({ id: `${labelPrefix || componentName}.${id}` }, values);
    };
}