import React from 'react';
import { useLabels } from '../../utils/translations';
import { DefinitionList } from '../index';
import { Section, SectionHeader } from '../index';
import { useIntl } from 'react-intl';
import css from "./Pricing.css"

const componentName = 'Pricing';

export default function Pricing({ config }) {
    const intl = useIntl();
    const getLabel = useLabels("Pricing", intl)

    const definitions = [];
    Object.keys(config["rates"]).forEach(k => {
        if (config["rates"][k] > 0) {
            definitions.push({
                type: k,
                description: `${Math.round(parseInt(config["rates"][k]) / 100, 0)} €`,
            })
        }
    })

    return (
        <Section id={'pricing'}>
            <SectionHeader title={getLabel('title')} />
            <DefinitionList componentName={componentName} definitions={definitions} id={'pricing'} />
            {config["pricing"]["footNote"] ? (
                <p className={css.note}>
                    {getLabel(`${config["slug"]}.footNote`)}
                </p>
            ) : null}
        </Section>
    );
}