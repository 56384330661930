import classNames from 'classnames';
import React, { useContext, useState } from 'react';
import { useLabels } from '../../utils/translations';
import { VIEWPORT_MEDIUM, useWindowSize } from '../../utils/viewport';
import { isDayBooked, isDayBlocked, rangeContainsBookedDay } from "../../utils/calendar";
import { Section, SectionHeader } from '../index';
import css from './Availability.css';
import 'react-dates/initialize';
import { DayPickerRangeController } from 'react-dates';
import { START_DATE } from 'react-dates/constants';
import { useIntl } from 'react-intl';
import { BookingContextProvider, useBookingContext } from '../../context/BookingContext';

const Availability = ({
    minimumDate,
    config
}) => {
    const { calendarIsLoaded, setBookingModalIsOpen, bookingModalIsOpen, startDate, endDate, bookedDates, setStartDate, setEndDate } = useBookingContext();
    const windowSize = useWindowSize();
    const [focusedInput, setFocusedInput] = useState(START_DATE);
    const intl = useIntl();
    const getLabel = useLabels('Availability', intl);

    const renderDayContents = day => {
        const classes = classNames(css.dayContents, isDayBooked(day, bookedDates) ? css.dayBooked : null);
        return (
            <span className={css.dayWrapper}><span className={classes}>{day.format('D')}</span></span>
        );
    };
    

    const onDatesChange = ({ startDate, endDate }) => {
        // Verify that there are no blocked days within the range
        if ((startDate && endDate === null) || !rangeContainsBookedDay(startDate, endDate, bookedDates)) {
            setStartDate(startDate);
            if (endDate === null) {
                setEndDate(startDate);
            } else {
                setEndDate(endDate);
            }
        }
    };

    const onFocusChange = focusedInput => {
        setFocusedInput(!focusedInput ? START_DATE : focusedInput);
    };

    const onButtonClick = () => {
        setBookingModalIsOpen(true);
        // bookingData.bookingModalIsOpen = !bookingData.bookingModalIsOpen;
    };

    const numberOfMonths = windowSize.width < VIEWPORT_MEDIUM ? 1 : 2;
    const hasSelectedDates = startDate && endDate;

    return (
        <BookingContextProvider>
            <Section className={css.root} id={'availability'}>
                {calendarIsLoaded ? (
                    <DayPickerRangeController
                        enableOutsideDays={false}
                        endDate={endDate}
                        minDate={minimumDate}
                        firstDayOfWeek={1}
                        focusedInput={focusedInput}
                        isDayBlocked={(day) => isDayBlocked(day, bookedDates)}
                        numberOfMonths={numberOfMonths}
                        onDatesChange={onDatesChange}
                        onFocusChange={onFocusChange}
                        renderDayContents={renderDayContents}
                        minimumNights={0}
                        startDate={startDate}
                        transitionDuration={0}
                        weekDayFormat="ddd"
                    />
                ) : null}
                <div className={css.mobileControls}>
                    <button className={css.button} disabled={!hasSelectedDates} onClick={onButtonClick} type={'button'}>
                        {getLabel(`submit.${hasSelectedDates ? 'datesSelected' : 'noDatesSelected'}`)}
                    </button>
                </div>
            </Section>
        </BookingContextProvider>
    );
}
export default Availability;