import React, { useEffect, useState } from "react";
import moment from "moment";
import classNames from "classnames";
import { useIntl } from "react-intl";
import {
    BookingPanel,
    Availability,
    Highlights,
    LocationMap,
    Photos,
    Equipment,
    Pricing,
    TopBar,
    ContactDetails,
    TermsAndConditions,
    Footer,
    Amenities,
    Instructions
} from '../components';
import { BookingContextProvider } from "../context/BookingContext";
import { useLabels } from '../utils/translations';
import css from "../App.css";
import Hero from "../components/Hero/Hero";
import services from "../services.json"

const today = moment().startOf('day');
const minimumDate = today.clone().startOf('month');

const SECTION_HIGHLIGHTS = "highlights";
const SECTION_AMENITIES = "amenities";
const SECTION_GALLERY = "gallery";
const SECTION_EQUIPMENT = "equipment";
const SECTION_PRICING = "pricing";
const SECTION_AVAILABILITY = "availability";
const SECTION_INSTRUCTIONS = "instructions";

const ServiceContent = ({ section, config, bookedDates = [], updateBookingData = () => {} }) => {
    const service = config["slug"];
    switch (section) {
        case SECTION_HIGHLIGHTS:
            return <Highlights config={config} />
        case SECTION_AMENITIES:
            return <Amenities service={service} config={config} />
        case SECTION_GALLERY:
            return <Photos service={service} config={config} />
        case SECTION_EQUIPMENT:
            return <Equipment service={service} config={config} />
        case SECTION_PRICING:
            return <Pricing service={service} config={config} />
        case SECTION_INSTRUCTIONS:
            return <Instructions config={config} />
        case SECTION_AVAILABILITY:
            return <Availability config={config} updateBookingData={updateBookingData} bookedDates={bookedDates} />
        default:
            console.log(`Not found: ${section}`)
            return null;
    }
}

export default function ServicePage ({ service }) {
    const config = services[service];
    const intl = useIntl();
    const getLabel = useLabels("ServicePage", intl);
    const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);
    // const { bookingModalIsOpen } = useBookingContext();

    const termsClasses = classNames(css.termsAndConditions, showTermsAndConditions ? css.show : null);
    

    const handleToggleShowTerms = () => setShowTermsAndConditions(!showTermsAndConditions);

    return (
        <BookingContextProvider>
            <main>
                <TopBar hideNavigation={false} config={config} />
                <Hero service={service} config={config} />
                <div className={css.details}>
                    <div className={css.contentWrapper}>
                        <div className={css.serviceContent}>
                            {config["sections"].map((section) => <ServiceContent key={`${section}-section`} section={section} config={config} />)}
                        </div>
                        <div className={css.sideBar}>
                            <BookingPanel config={config} handleToggleShowTerms={handleToggleShowTerms} />
                        </div>
                    </div>
                </div>
                <LocationMap />
                <ContactDetails />
                <Footer handleToggleShowTerms={handleToggleShowTerms}/>
                <div className={css.overlay}/>
                <div className={termsClasses}>
                    <TermsAndConditions handleToggleShowTerms={handleToggleShowTerms} />
                </div>
            </main>
        </BookingContextProvider>
    );
}