import React from 'react';
import { withLabels } from '../../utils/translations';
import css from './TermsAndConditions.css';

class TermsAndConditions extends React.Component {
    render() {
        const { getLabel, handleToggleShowTerms } = this.props;
        return (
            <div className={css.root}>
                <h1>Kümblustünni renditingimused</h1>
                <h2>1. Kümblustünni kasutamise tingimused</h2>
                <p>
                    1.1. Rentnik on kohustatud kasutama Kümblustünni korralikult ja heaperemehelikult. Vastavalt lepingus määratud tingimustele ja sihtotstarbele. Rendilevõtja tutvub enne kasutamist kümblustünni kasutusjuhendiga tünnisellid.ee.
                </p>
                <p>
                    1.2. Kümblustünni on keelatud kasutada teiste transpordivahendite pukseerimiseks, õppesõiduks, sõiduspordi treeninguteks ja võistlusteks, tasuliste teenuste osutamiseks ning kaubaveoks. Leppetrahv 400€.
                </p>
                <h2>2. Vajalikud dokumendid</h2>
                <p>
                    2.1. Kümblustünni võib rentida vähemalt 18 aastane teovõimeline isik, kes omab B-kategooria juhiluba.
                </p>
                <h2>3. Rentniku kohustused Kümblustünni kasutamisel</h2>
                <p>
                    3.1. Rentnik on kohustatud enne rendiperioodi algust ja Rendifirma poolt Kümblustünni üleandmist Rentnikku valdusse Kümblustünni põhjalikult üle vaatama võimalike kahjustuste tuvastamiseks.
                </p>
                <p>
                    3.2. Rentnik on kohustatud tasuma Rendifirmale kõikide Rentnikule rendiperioodil esitatud trahvinõuete eest täies ulatuses (valesti parkimine, liikluseeskirjade rikkumine jms.).Rendifirmal on õigus nimetatud trahvinõuded Rentnikult sisse nõuda 6 kuu jooksul, juhul kui Rentnik ei ole neid nõudeid Rendifirmale tasunud ja nende nõuete olemasolu ilmneb pärast rendiperioodi lõppemist. Selliste nõuete tasumisel kohustub Rentnik alluma kohesele sundtäitmisele.
                </p>
                <h2>4. Rentniku vastutus</h2>
                <p>
                    4.1. Rentnik vastutab Kümblustünni hävimise, kaotsimineku ja kahjustumise eest, mis toimub ajal kui Kümblustünn on Rentniku kasutuses. Rentnik ei vastuta Kümblustünni hariliku kulumise, halvenemise ja muutuste eest, mis kaasnevad Kümblustünni hariliku kulumise, halvenemise ja muutuste eest, mis kaasnevad Kümblustünni renditingimuste järgse kasutamisega.
                </p>
                <p>
                    4.2. Rentnik on täies ulatuses vastutav Kümblustünnile või selle seadmetele tekitatud kahju eest kui:
                </p>
                <p>
                    4.2.1. Kümblustünnile ja Rendifirmale on tekitatud kahju tahtlikult ja/või Rentniku süül;
                </p>
                <p>
                    4.2.2. Kümblustünni on juhitud alkoholijoobes ja/või narkootilise aine mõju all
                </p>
                <p>
                    4.2.3. Kümblustünni on kasutatud Eesti Vabariigi seadusi rikkudes ja/või seaduse vastastel eesmärkidel (kuriteo sooritamisel vms);
                </p>
                <p>
                    4.2.4. Kümblustünni on kasutatud mitte vastavuses kehtivate liikluseeskirjadega;
                </p>
                <p>
                    4.2.5. Kümblustünni on kasutatud käesoleva renditingimuste punktis 1 märgitud tingimusi rikkudes;
                </p>
                <p>
                    4.2.6. Rentniku hooletuse või süülise käitumise tõttu on Kümblustünn kaotsi läinud või varastatud;
                </p>
                <p>
                    4.2.7. Sõidetud on tühja rehviga;
                </p>
                <p>
                    4.3. Rentnik on kohustatud Rendifirmale hüvitama:
                </p>
                <p>
                    4.3.1. Kümblustünni või selle seadmetele tekitatud kahjustused täies ulatuses, vastavalt Rendifirma asukohajärgse Kümblustünni müügi- ja teenindusettevõtte hinnakirjale;
                </p>
                <p>
                    4.3.2. Kümblustünni kaotsiläinud, varastatud või kahjustatud osade ja lisavarustuse maksumuse täies ulatuses punktis 4.3.1 toodu kohaselt juhul, kui tegemist ei ole kindlustusjuhtumiga;
                </p>
                <p>
                    4.3.3. Rentnik on kohustatud tasuma Rendifirmale purunenud rehvi remondikulud summas 100€ ühe purunenud rehvi kohta või rehvi asendamise kulud kui rehv on täielikult purunenud ja muutunud kasutuskõlbmatuks;
                </p>
                <p>
                    4.3.4. Juhul kui Rentniku kasutuses oleval Kümblustünnil tekib kahjustusi või kui Kümblustünn varastatakse, läheb kaotsi või hävib on rentnik kohustatud tasuma kogu kahjusumma.
                </p>
                <p>
                    4.4. Rentnik kannab täielikku vastutust Kümblustünni kui kõrgema ohu allika tekitatud võimaliku kahju eest.
                </p>
                <h2>5. Rendiperiood</h2>
                <p>
                    5.1. Lühimaks rendiperioodiks on 1 ööpäev.
                </p>
                <p>
                    5.2. Rendiperioodi alguse ja lõpu lepivad Rentnik ja Rendileandja kokku täiendavalt. Kümblustünni kokkulepitud päeval hilisemal tagastamisel lisandub rendiperioodile üks ööpäev, kui ei ole kokku lepitud teisiti.
                </p>
                <h2>6. Renditasu</h2>
                <p>
                    6.1. Rendihinnas sisaldub tasu Kümblustünni kasutamise eest, hoolduse ning liikluskindlustuse maksumus.
                </p>
                <p>
                    6.2. Rendihind tasutakse enne rendiperioodi algust.
                </p>
                <h2>
                    7. Broneerimine
                </h2>
                <p>
                    7.1. Kümblustünni saab broneerida kodulehel olevast online kalendrist või emaili teel. Peale broneerimist kinnitame 24 tunni jooksul broneeringu võimalikkust, ning on võimalik tasuda broneeringu eest Luu OÜ arveldusarvele.
– Kui rentnik loobub broneeringust kaks nädalat enne rendiperioodi algust, tagastatakse broneerimistasu täielikult.
– Alla kahenädalase etteteatamise puhul broneerimistasu ei tagastata.
                </p>
                <h2>8. Rendifirma kohustused</h2>
                <p>
                    8.1. Rendifirma kohustab andma Rentnikule töökorras Kümblustünni kokkulepitud/renditingimustel märgitud ajal ja kohas. Juhul kui Rendileandja ei anna Kümblustünni Rentnikule kokkulepitud ajal ja kohas, on Rentnikul õigus saada allahindlust renditasust.
                </p>
                <p>
                    8.2. Kümblustünni üleandmise ajalise viivituse eest on Rendifirma kohustatud hüvitama Rentnikule kahjutasu kuni ühe rendipäeva renditasu ulatuses.
                </p>
                <p>
                    8.3. Rendifirma jätab endale õiguse vahetada kokkulepitud Kümblustünn teise Kümblustünni vastu Kümblustünni võimaliku müügi korral.
                </p>
                <h2>9. Rentniku kohustused kahjustuste, avarii või varguste korral</h2>
                <p>
                    9.1. Rentnik on kohustatud koheselt teavitama Rendifirmat kõikidest Kümblustünnile tekitatud kahjustustest, Kümblustünniga toimunud avariist või Kümblustünni vargusest ning toimima vastavalt Rendifirma poolt Kümblustünni rentimisel antud juhendile.
                </p>
                <p>
                    9.2. Liiklusavarii ja varguse korral on Rentnik kohustatud juhtunust teavitama ka politseid.
                </p>
                <p>
                    9.3. Õnnetusjuhtum tuleb vormistada kirjalikult, süüdlaselt võtta tunnistus ja allkiri.
                </p>
                <p>
                    9.4. Juhul kui Rentnik ei toimi vastavalt punktis 10 sätestatule, vastutab ta täies ulatuses kõikide Rendifirmale tekitatud kahjude eest.
                </p>
                <h2>10. Rendifirma vastutus Kümblustünni rikke korral</h2>
                <p>
                    10.1.Rendifirma ei vastuta otseselt või kaudselt Rentnikule tekkivate kahjude eest, mis tulenevad rendiperioodi katkemise või viivitusega seoses Kümblustünni rikkega. Kui rike tekib rendiperioodi kestel, on Rentnikul õigus saada hinnaalandust renditasust, kui Rendifirma ei suuda tõestada, et on teinud kõik endast sõltuva Kümblustünni korrasolekuks.
                </p>
                <h2>11. Kümblustünni tagastamine Rendilepingus ettenähtud ajal</h2>
                <p>
                    11.1. Kümblustünn tuleb tagastada kokkulepitud ajal ja kohas. Rendileping loetakse lõpetatuks kui Kümblustünn on tagastatud rendilepingus märgitud paigas.
                </p>
                <p>
                    11.2. Juhul kui Rentnik ei ole tagastanud Kümblustünni Rendifirmale kokkulepitud ajal ja kohas ning Rendifirmaga ei ole kokku lepitud rendiperioodi pikendamise osas, teatab Rendifirma politseile Kümblustünni kadumisest, kuulutab Kümblustünni tagaotsitavaks ja võib kasutusele võtta muud vajalikud abinõud.
                </p>
                <p>
                    11.3. Rendiperioodi ületamisel ilma sellekohase kokkuleppeta ja Rendifirma nõusolekuta, on Rendifirmal õigus nõuda Rentnikult kahekordset renditasu rendiperioodi ületanud iga päeva eest, maksab ka iga üleläinud tund (rendipäeva tasu korda kaks jagada 24) ja on kohustatud hüvitama kõik järgmise rentniku poolt esitatud dokumenteeritud kulud (broneeringud, jne), mis on tekkinud seoses Kümblustünni tagastamise hilinemisega.
                </p>
                <h2>12. Kümblustünni tagastamine</h2>
                <p>
                    12.1. Kümblustünn tuleb tagastada samas seisukorras, nagu see oli rendile võtmise hetkel.
                </p>
                <p>
                    12.2. Rikked ja vigastused, mida ei olnud rendile andmise hetkel ja mida ei saa lugeda normaalseks kulumiseks, hüvitab rentnik.
                </p>
                <h2>13. Rendilepingu katkemine</h2>
                <p>
                    13.1. Rendifirmal on õigus katkestada Rendileping kui tuleb ilmsiks, et Rentnik on oluliselt rikkunud Rendilepingu tingimusi, on Kümblustünni rentides esitanud Rendifirmale valeandmeid või ei tule toime Kümblustünni ekspluatatsiooniga.
                </p>
                <p>
                    13.2. Kui Kümblustünni kasutamine muutub võimatuks Kümblustünni kahjustuse, rikke, avarii või varguse tõttu, loetakse Rendileping lõppenuks hetkest, millal sellekohane kirjalik informatsioon jõuab Rendifirmani.
                </p>
                <p>
                    13.3. Kui Rendifirma nõuab Lepingu katkemist käesolevas punktis toodud põhjustel, on Rentnik kohustatud Kümblustünni koheselt tagastama Rendifirmale.
                </p>
                <h2>
                    14. Territoorium
                </h2>
                <p>
                    14.1 Rentnikul on keelatud sõita Kümblustünniga väljaspool Eestit, kui tal puudub selleks Rendifirma eelnev kirjalik nõusolek.
                </p>
                <h2>
                    15. Vaidluste lahendamine
                </h2>
                <p>
                    15.1. Käesolevast renditingimustest tulenevad vaidlused lahendatakse Rendifirma ja Rentniku vaheliste
                </p>
                <p>
                    15.2. Juhul kui Rendifirma ja Rentnik ei jõua kokkuleppele, lahendatakse vaidlused Harju maakohtus.
                </p>
                <div className={css.controls}>
                    <button onClick={handleToggleShowTerms} type={'button'}>{getLabel('close')}</button>
                </div>
            </div>
        );
    }
}

export default withLabels(TermsAndConditions, 'TermsAndConditions');