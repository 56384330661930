import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { IntlProvider } from "react-intl";
import translationsEnglish from './translations/en';
import translationsEstonian from './translations/et';
import moment from 'moment';
import 'moment/locale/et';
import {
    BrowserRouter,
    Routes,
    Route,
  } from "react-router-dom";
import { LandingPage, ServicePage } from './routes';

const translations = {
    'en': translationsEnglish,
    'et': translationsEstonian,
};
const language = 'et';
moment.locale('et');

const SERVICE_CARAVAN = "caravan";
const SERVICE_HOT_TUB = "hot-tub";

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);
root.render(
    <IntlProvider locale={language} messages={translations[language]}>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<ServicePage service={SERVICE_HOT_TUB} />} />
            </Routes>
        </BrowserRouter>
    </IntlProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
