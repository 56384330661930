import React, { useEffect, useContext, useCallback, useRef, useState } from 'react';
import { Link } from 'react-scroll';
import css from './BookingPanel.css';
import { Form, FormSpy } from 'react-final-form';
import { CheckField, TextField } from '../index';
import classNames from 'classnames';
import { Tooltip } from '../index';
import { useLabels } from '../../utils/translations';
import * as validators from '../../utils/validators';
import emailjs from 'emailjs-com';
import config from '../../config';
import { faCalendarCheck, faCalendarPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useIntl } from 'react-intl';
import { useClickedOutsideElement } from '../../utils/viewport';
import { useBookingContext } from '../../context/BookingContext';
import moment from 'moment';
import BreakDownCalculator from "../../utils/breakdown";

import globalCss from "../../App.css"

const { topOffset, transitionTime } = config.scroll;

const SECURITY_DEPOSIT = 20000;

const DATE_FORMAT_LONG = 'dddd, MMMM Do, YYYY';
const DATE_FORMAT_SHORT = 'YYYY-MM-DD';

const FormattedMoney = props => {
    const classes = classNames(css.price, props.className);
    return (
        <span className={classes}>
            &euro; {(props.amount / 100).toFixed(2)}
        </span>
    );
};

const BookingPanel = ({ handleToggleShowTerms, config }) => {
    const { setBookingModalIsOpen, startDate, endDate, bookingModalIsOpen, isSent, selectedOptions, setIsSent } = useBookingContext();
    const rootNode = useRef();
    const intl = useIntl();
    const getLabel = useLabels('BookingPanel', intl);
    const clickedOutside = useClickedOutsideElement(rootNode);
    const breakDownCalculator = new BreakDownCalculator(config, getLabel);
    const hasSelectedDates = startDate && endDate;
    const lineItems = hasSelectedDates ? breakDownCalculator.calculate({
        startDate,
        endDate,
        selectedOptions,
    }) : [];

    const escFunction = useCallback((event) => {
        if (event.key === "Escape") {
            setBookingModalIsOpen(false);
        }
    }, []);

    useEffect(() => {
        // document.addEventListener('mousedown', handleClick);
        document.addEventListener("keydown", escFunction, false);

        return function cleanup() {
            // document.removeEventListener('mousedown', handleClick);
            document.removeEventListener("keydown", escFunction, false);
        }
    }, [ rootNode ]);

    useEffect(() => {
        if (clickedOutside && bookingModalIsOpen) {
            setBookingModalIsOpen(false);
        }
    }, [clickedOutside])

    const calculateLineTotal = lineItems => {
        return lineItems.map(lineItem => lineItem.lineTotal).reduce((a, b) => a + b, 0);
    };

    const toggleOption = e => {
        // bookingData.options = {
        //     ...bookingData.options,
        //     [e.target.name]: e.target.checked,
        // };
    };

    const onButtonClick = () => setBookingModalIsOpen(!bookingModalIsOpen);

    const onMakeReservation= (values) => {
        console.log(values)
        // const combinedValues = {
        //     ...values,
        //     startDate: bookingData.startDate.format(DATE_FORMAT_SHORT),
        //     endDate: bookingData.endDate.format(DATE_FORMAT_SHORT),
        //     totalPrice: `€ ${(calculateLineTotal(lineItems) / 100).toFixed(2).toString()}`
        // };

        const combinedValues = {
            name: values.name,
            email: values.email,
            message: values.message,
            phone: values.phone,
            startDate: startDate.format(DATE_FORMAT_SHORT),
            endDate: endDate.format(DATE_FORMAT_SHORT),
        }

        const templateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
        const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
        const userId = process.env.REACT_APP_EMAILJS_USER_ID;

        emailjs.send(serviceId, templateId, combinedValues, userId)
            .then(result => {
                setIsSent(true);
                console.log('All ok', result);
            })
            .catch(result => {
                console.log('Something went wrong', result);
            });

    };

    // Show modal
    if (bookingModalIsOpen) {
        document.body.classList.add(globalCss.withModal);
    } else {
        document.body.classList.remove(globalCss.withModal);
    }

    // Render options
    const hasConfirmedPrice = false;
    const labelSuffix = hasSelectedDates
        ? hasConfirmedPrice
            ? 'priceConfirmed'
            : 'datesSelected'
        : 'noDatesSelected';
    const bookingDuration = hasSelectedDates ? endDate.diff(startDate, 'days') : 0;
    const disableSubmit = !hasSelectedDates && !hasConfirmedPrice;
    const classes = classNames(css.root, bookingModalIsOpen ? css.isModal : null, isSent ? css.isSent : null);
    const dateFormat = bookingModalIsOpen ? DATE_FORMAT_LONG : DATE_FORMAT_SHORT;

    // Render
    return isSent ? (
        <div className={classes} ref={rootNode}>
            <h3 className={css.panelHeader}>
                {getLabel(`title.requestSent`)}
            </h3>
            <p>
                {getLabel('requestSent')}
            </p>
            <button className={css.buttonClose} type={'button'} onClick={() => setBookingModalIsOpen(false)}>
                {getLabel('close')}
            </button>
        </div>
    ) : (
        <div className={classes} ref={rootNode}>
            <h3 className={css.panelHeader}>
                {getLabel(`title.${labelSuffix}`, { days: bookingDuration + 1 })}
                <button className={css.mobileCloseButton} onClick={() => bookingModalIsOpen = false}>
                    <FontAwesomeIcon icon={faTimes} />
                </button>
            </h3>
            <Form
                initialValues={{
                    pickUpDate: startDate ? startDate.format(dateFormat) : null,
                    dropOffDate: endDate ? endDate.format(dateFormat) : null,
                }}
                onSubmit={onMakeReservation}
                render={({
                             handleSubmit,
                             invalid
                         }) => (
                    <form className={css.form} onSubmit={handleSubmit}>
                        <FormSpy
                            subscription={{ values: true }}
                            onChange={state => {
                                const { values } = state;
                                // updateBookingData(values);
                            }}
                        />
                        <div className={css.breakdown}>
                            <div className={css.dates}>
                                <div className={css.dateFieldWrapper}>
                                    {startDate ? (
                                        <span className={css.dateLabel}>
                                            {getLabel('pickUpDate')}
                                        </span>
                                    ) : null}
                                    <Link
                                        className={classNames(css.dateValue, !startDate ? css.noDateSelected : null)}
                                        to={'availability'}
                                        duration={transitionTime}
                                        offset={topOffset}
                                        smooth={'easeInOutQuart'}
                                    >
                                        <FontAwesomeIcon icon={startDate ? faCalendarCheck : faCalendarPlus} className={css.dateIcon} />
                                        {startDate ? startDate.format(dateFormat) : getLabel('pickUpDate')}
                                    </Link>
                                </div>
                                <div className={css.dateFieldWrapper}>
                                    {endDate ? (
                                        <span className={css.dateLabel}>
                                            {getLabel('dropOffDate')}
                                        </span>
                                    ) : null}
                                    <Link className={classNames(css.dateValue, !endDate ? css.noDateSelected : null)}
                                          to={'availability'}
                                          duration={transitionTime}
                                          offset={topOffset}
                                          smooth={'easeInOutQuart'}
                                    >
                                        <FontAwesomeIcon icon={endDate ? faCalendarCheck : faCalendarPlus} className={css.dateIcon} />
                                        {endDate ? endDate.format(dateFormat) : getLabel('dropOffDate')}
                                    </Link>
                                </div>
                            </div>
                            {lineItems.length && 1 == 2 ? (
                                <>
                                    <ul className={css.lineItems}>
                                        {lineItems.map((lineItem, index) => {
                                            return (
                                                <li className={css.lineItem} key={`line-item-${index}`}>
                                                    <span>
                                                        {getLabel(`lineItem.${lineItem.label}`, lineItem.values)}
                                                        {lineItem.tooltip ? (
                                                            <Tooltip
                                                                className={css.tooltip}
                                                                text={getLabel(`lineItem.${lineItem.label}.tooltip`)}
                                                            />
                                                        ) : null}
                                                    </span>
                                                    <FormattedMoney
                                                        amount={lineItem.lineTotal}
                                                        className={lineItem.lineTotal < 0 ? css.discount : null}
                                                    />
                                                </li>
                                            );
                                        })}
                                        <li className={css.lineItemTotal}>
                                            {getLabel('lineItemTotal')}
                                            <FormattedMoney amount={calculateLineTotal(lineItems)}/>
                                        </li>
                                        <li className={css.deposit}>
                                            <span className={css.depositLabelWrapper}>
                                                {getLabel('deposit')}
                                                <Tooltip
                                                    className={css.tooltip}
                                                    text={getLabel('deposit.tooltip')}/>
                                            </span>
                                            <FormattedMoney amount={SECURITY_DEPOSIT}/>
                                        </li>
                                    </ul>
                                </>
                            ) : null}
                            <button className={css.button} type={'button'} disabled={disableSubmit} onClick={onButtonClick}>
                                {getLabel(`submit.${labelSuffix}`)}
                            </button>
                        </div>
                        <div className={css.details}>
                            <div className={css.fieldWrapper}>
                                <TextField
                                    className={css.field}
                                    name={'name'}
                                    component={'input'}
                                    id={'name'}
                                    label={getLabel('name')}
                                    placeholder={getLabel('name.placeholder')}
                                    type={'text'}
                                    validate={validators.isRequired(getLabel('name.required'))}
                                />
                            </div>
                            <div className={css.fieldWrapper}>
                                <label className={css.fieldLabel} htmlFor={'email'}>
                                    {getLabel('email')}
                                </label>
                                <TextField
                                    className={css.field}
                                    name={'email'}
                                    component={'input'}
                                    id={'email'}
                                    placeholder={getLabel('email.placeholder')}
                                    type={'text'}
                                    validate={validators.composeValidators(
                                        validators.isRequired(getLabel('email.required')),
                                        validators.isValidEmail(getLabel('email.invalid'))
                                    )}
                                />
                            </div>
                            <div className={css.fieldWrapper}>
                                <label className={css.fieldLabel} htmlFor={'phone'}>
                                    {getLabel('phone')}
                                </label>
                                <TextField
                                    className={css.field}
                                    name={'phone'}
                                    component={'input'}
                                    id={'phone'}
                                    placeholder={getLabel('phone.placeholder')}
                                    type={'text'}
                                    validate={validators.isRequired(getLabel('phone.required'))}
                                />
                            </div>
                            <div className={css.fieldWrapper}>
                                <label className={css.fieldLabel} htmlFor={'message'}>
                                    {getLabel('message')}
                                </label>
                                <TextField
                                    className={css.field}
                                    name={'message'}
                                    component={'textarea'}
                                    id={'message'}
                                    placeholder={getLabel('message.placeholder')}
                                />
                            </div>
                            <div className={css.termsAndConditions}>
                                {getLabel('agreeToTermsAndConditions')}
                                <a
                                    className={css.termsAndConditionsLink}
                                    href={'#terms-and-conditions'}
                                    onClick={handleToggleShowTerms}
                                >{getLabel('termsAndConditions')}</a>
                            </div>
                            <button className={css.button} type={'submit'} disabled={invalid}>
                                {getLabel('submit.makeReservation')}
                            </button>
                        </div>
                    </form>
                )}
            />
        </div>
    );
}
export default BookingPanel;