import moment from "moment";

const MAPPED_DATE_FORMAT = 'YYYY-MM-DD';

export default class BreakDownCalculator {
    constructor(config, getLabel) {
        this.config = config;
        this.getLabel = getLabel;
        this.lineItems = [];
        this.mappedDates = [];
    }

    /**
     * Calculate the full breakdown including all subsections
     * 
     * Order:
     * 1. Special dates
     * 2. Full weeks
     * 3. Full weekends
     * 4. Weekend days
     * 5. Days
     */
    calculate(context) {
        // Reset local state
        this.lineItems = [];
        this.mappedDates = [];

        // Assign local variables
        this.context = context;

        // Special dates
        const specialDatesLineItems = this.calculateSpecialDateLineItems();    

        // Week discounts
        const weeksLineItem = this.calculateWeeksLineItem();


        // Weekends (Friday until Sunday, excluding special dates)
        const weekendsLineItem = this.calculateWeekendsLineItem();

        // Weekend days (Saturday, Sunday, excluding special dates)
        const weekendDaysLineItem = this.calculateWeekendDaysLineItem();

        // Daily rate items
        const dailyRateLineItem = this.calculateDailyRateLineItem();

        // Construct final line items
        if (dailyRateLineItem !== null) {
            this.lineItems.push(dailyRateLineItem);
        }
        if (weeksLineItem !== null) {
            this.lineItems.push(weeksLineItem);
        }
        if (weekendsLineItem !== null) {
            this.lineItems.push(weekendsLineItem);
        }
        if (weekendDaysLineItem !== null) {
            this.lineItems.push(weekendDaysLineItem);
        }
        this.lineItems.push(...specialDatesLineItems);
        this.lineItems.push(...this.calculateAdditionalFees());

        return this.lineItems;
    }

    /**
     * Calculate the line item for regular days
     */
    calculateDailyRateLineItem() {
        const { daily } = this.config.rates;
        const { startDate, endDate } = this.context;

        const remainingDays = endDate.diff(startDate, 'days') - this.mappedDates.length;
        return remainingDays <= 0 ? null : {
            label: 'bookingPrice',
            values: {
                days: remainingDays,
                price: daily / 100,
            },
            units: remainingDays,
            unitPrice: daily,
            lineTotal: remainingDays * daily,
        }
    }

    /**
     * Calculate the line items for the additional fees, both optional and mandatory
     */
    calculateAdditionalFees() {
        const { selectedOptions } = this.context;
        const { requiredLineItems } = this.config;

        const lineItems = [];
        for (const selectedOption of selectedOptions) {
            lineItems.push({
                label: selectedOption,
                units: 1,
                unitPrice: this.config.rates[selectedOption],
                lineTotal: this.config.rates[selectedOption],
            });
        }
        for (const mandatoryItem of requiredLineItems) {
            // lineItems.push({
            //     label: mandatoryItem,
            //     units: 1,
            //     unitPrice: config.rates[mandatoryItem],
            //     lineTotal: config.rates[mandatoryItem],
            // });
        }

        return lineItems;
    }

    /**
     * Calculate the line items for special dates
     */
    calculateSpecialDateLineItems() {
        const lineItems = [];
        const { startDate, endDate } = this.context;
        const { specialDailyRate } = this.config.rates;
        const specialDatesInRange = this.getSpecialDatesInRange(startDate, endDate);

        for (const specialDate of specialDatesInRange) {
            this.mappedDates.push(specialDate.format(MAPPED_DATE_FORMAT));
            lineItems.push({
                label: 'specialDate',
                values: {
                    date: this.getLabel(`XXXX-${specialDate.format("MM-DD")}`),
                },
                units: 1,
                unitPrice: specialDailyRate,
                lineTotal: specialDailyRate,
            });
        }

        return lineItems;
    }

    mapDatesInRange(start, end) {
        for (let m = start.clone(); m.isBefore(end); m.add(1, "days")) {
            this.mappedDates.push(m.format(MAPPED_DATE_FORMAT));
        }
    }

    /**
     * Calculat the full weeks line item
     */
    calculateWeeksLineItem() {
        const { startDate, endDate } = this.context;
        const { week } = this.config.rates;
        const specialDates = this.getSpecialDatesInRange(startDate, endDate);
        let weeks = 0;

        if (specialDates.length > 0) {
            for (let i = 0; i< specialDates.length; i++) {
                const specialMoment = specialDates[i].clone();

                // First special date
                if (i === 0) {
                    const beforeWeeks = Math.floor(specialMoment.diff(startDate, "days") / 7);
                    if (beforeWeeks > 0) {
                        const rangeBeforeEnd = startDate.clone().add(beforeWeeks * 7, "days");
                        this.mapDatesInRange(startDate, rangeBeforeEnd);
                        weeks += beforeWeeks;
                    }
                }

                // Last special date
                if (i === specialDates.length - 1) {
                    const afterWeeks = Math.floor((endDate.diff(specialMoment, "days") - 1) / 7);
                    if (afterWeeks > 0) {
                        this.mapDatesInRange(specialMoment, afterWeeks);
                        weeks += afterWeeks;
                    }
                // Space between this special date and the next (if any)
                } else if (i !== specialDates.length - 1) {
                    const dayAfterSpecialMoment = specialMoment.clone().add(1, "days");
                    const nextSpecialMoment = specialDates[i + 1].clone()
                    const inBetweenWeeks = Math.floor(nextSpecialMoment.diff(dayAfterSpecialMoment, "days") / 7);
                    if (inBetweenWeeks > 0) {
                        this.mapDatesInRange(dayAfterSpecialMoment, nextSpecialMoment);
                        weeks += inBetweenWeeks;
                    }
                }
            }
        } else {
            // Simplified, without special dates
            weeks = Math.floor(endDate.diff(startDate, "days") / 7);
            if (weeks > 0) {
                const rangeEnd = startDate.clone().add(weeks * 7, "days");
                this.mapDatesInRange(startDate, rangeEnd);
            }
        }

        
        if (weeks > 0) {
            // Return the line item
            return {
                label: 'week',
                values: {
                    number: weeks,
                    price: week / 100,
                },
                units: weeks,
                unitPrice: week,
                lineTotal: week * weeks,
            };
        } else {
            return null;
        }
    }

    /**
     * Calculate the weekends line item
     */
    calculateWeekendsLineItem() {
        const { startDate, endDate } = this.context;
        const { weekend } = this.config.rates;
        const weekends = this.getWeekendsInRange(startDate, endDate);
        let fullWeekends = 0;

        for (const weekend of weekends) {
            const specialDates = this.getSpecialDatesInRange(weekend.start, weekend.end);
            if (specialDates.length === 0) {
                const friday = weekend.start.clone().format(MAPPED_DATE_FORMAT);
                const saturday = weekend.start.clone().add(1, "days").format(MAPPED_DATE_FORMAT);

                if (!this.mappedDates.includes(friday) && !this.mappedDates.includes(saturday)) {
                    this.mappedDates.push(friday);
                    this.mappedDates.push(saturday);
                    fullWeekends++;
                }
            }
        }

        if (fullWeekends > 0) {
            return {
                label: 'weekend',
                values: {
                    number: fullWeekends,
                    price: weekend / 100,
                },
                units: 1,
                unitPrice: weekend,
                lineTotal: weekend * fullWeekends,
            };
        }

        return null;

    }

    /**
     * Calculate the weekend days line item
     */
    calculateWeekendDaysLineItem() {
        const { startDate, endDate } = this.context;
        const { weekendDaily } = this.config.rates;
        const weekendDaysInRange = this.getWeekendDaysInRange(startDate, endDate);
        let weekendDays = 0;

        for (const weekendDay of weekendDaysInRange) {
            if (!this.mappedDates.includes(weekendDay.format(MAPPED_DATE_FORMAT))) {
                let occursDuringWeekend = false;

                if (!occursDuringWeekend) {
                    this.mappedDates.push(weekendDay.format(MAPPED_DATE_FORMAT));
                    weekendDays++;
                }
            }
        }

        // Create the line item
        if (weekendDays > 0) {
            return {
                label: 'weekendDaily',
                values: {
                    number: weekendDays,
                    price: weekendDaily / 100,
                },
                units: 1,
                unitPrice: weekendDaily,
                lineTotal: weekendDaily * weekendDays,
            };
        }

        return null;
    }

    /**
     * Get all full weekends within a range 
     */
    getWeekendsInRange(start, end) {
        const weekends = [];
        const m = start.clone();

        while (m.isSameOrBefore(end)) {
            if (m.day() === 5) {
                const mSun = m.clone().add(2, "days");
                if (mSun.isSameOrBefore(end)) {
                    weekends.push({
                        start: m.clone(),
                        end: mSun.clone(),
                    });
                    
                    // Skip over the weekend
                    m.add(3, "days");
                } else {
                    break;
                }
            } else {
                m.add(1, "days");
            }
        }

        return weekends;
    }

    /**
     * Get all weekend days (Saturday and Sunday) within a range
     */
    getWeekendDaysInRange(start, end) {
        const weekendDays = [];
        const m = start.clone();

        while (m.isBefore(end)) {
            if (m.day() === 0 || m.day() === 6) {
                weekendDays.push(m.clone())
            }

            m.add(1, "days");
        }

        return weekendDays;
    }

    /**
     * Get all special dates within a range, based on the configuration
     */
    getSpecialDatesInRange(start, end) {
        const { specialDailyRate, specialDates } = this.config.rates;
        const specialDatesInRange = [];
        const specialDatesInRangeFormatted = [];

        if (specialDailyRate > 0 && specialDates.length > 0) {
            const yearsToCheck = [
                start.year(),
                end.year(),
            ];

            for (const y of yearsToCheck) {
                for (const specialDate of specialDates) {
                    const specialMoment = moment(specialDate.replace("XXXX", y), MAPPED_DATE_FORMAT);
                    const formattedSpecialDate = specialMoment.format(MAPPED_DATE_FORMAT);
                    if (specialMoment.isBetween(start, end, undefined, '[)') && !specialDatesInRangeFormatted.includes(formattedSpecialDate)) {
                        specialDatesInRange.push(specialMoment);
                        specialDatesInRangeFormatted.push(formattedSpecialDate)
                    }
                }
            }
        }

        return specialDatesInRange;
    }

    getLineItemTotal() {
        return this.lineItems.reduce((acc, cv) => acc + cv.lineTotal, 0)
    }
}

export const countWeekendsInRange = (startDate, endDate, exclusionDates = []) => {
    let count = 0;
    const m = startDate.clone();

    while (m.isSameOrBefore(endDate)) {
        // If it's Friday
        if (m.day() === 5) {
            const mSat = m.clone().add(1, "days");
            const mSun = m.clone().add(2, "days");
        }
    }
}