import React from "react";
import { useIntl } from "react-intl";
import { Link } from 'react-scroll';
import classNames from "classnames";
import css from './Hero.css';
import { useLabels } from '../../utils/translations';
import config from "../../config";

export const SERVICE_CARAVAN = "caravan"
export const SERVICE_HOT_TUB = "hotTub";

export default function Hero({ service = SERVICE_CARAVAN, config: serviceConfig }) {
    const intl = useIntl();
    const getLabel = useLabels("Hero", intl);
    const { transitionTime, topOffset } = config.scroll;
    const backgroundImageUrl = `/assets/gallery/${serviceConfig["slug"]}/${serviceConfig["headerImage"]}`;

    return (
        <div className={classNames(css.root)} id={'top'} style={{ backgroundImage: `url(${backgroundImageUrl})` }}>
            <Link className={css.textBox} to={'availability'} duration={transitionTime} offset={topOffset} smooth={'easeInOutQuart'}>
                <h1 className={css.headline}>{getLabel(`${service}.title`)}</h1>
                <h2 className={css.subHeadline}>{getLabel(`${service}.subTitle`)}</h2>
            </Link>
        </div>
    );
}
