import { func } from 'prop-types';
import React from "react";
import { withLabels } from '../../utils/translations';
import css from './Footer.css';
import { Link } from 'react-scroll';
import config from "../../config";

class Footer extends React.Component {
    render() {
        const { getLabel, handleToggleShowTerms } = this.props;
        const { transitionTime } = config.scroll;
        return(
            <footer className={css.root}>
                <span className={css.copyright}>&copy; 2024 {config.site.copyright}</span>
                <Link className={css.toTop} duration={transitionTime} to={'top'}>{getLabel('backToTop')}</Link>
                <button className={css.terms} onClick={handleToggleShowTerms} type={'button'}>{getLabel('termsAndConditions')}</button>
            </footer>
        )
    }
}

Footer.propTypes = {
    handleToggleShowTerms: func.isRequired,
};

export default withLabels(Footer, 'Footer');