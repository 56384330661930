import React, { useEffect, useState, useRef } from "react";
import mapboxgl from 'mapbox-gl';
import { useLabels } from '../../utils/translations';
import { Section, SectionHeader } from '../index';
import css from './LocationMap.css';
import config from '../../config';
import { useIntl } from "react-intl";
const { latitude, longitude } = config.location;

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

const LocationMap = (props) => {
    const intl = useIntl();
    const [zoom, setZoom] = useState(16);
    const getLabel = useLabels('LocationMap', intl);
    const map = useRef(null);
    const mapContainer = useRef(null);

    // Mount
    useEffect(() => {
        // Only load once
        if (map.current) return;

        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: "mapbox://styles/mapbox/light-v10",
            center: [longitude, latitude],
            zoom,
            interactive: true,
        });
    });

    useEffect(() => {
        // Wait for the map to load
        if (!map.current) return;

        map.current.loadImage('https://i.imgur.com/MK4NUzI.png', function(error, image) {
            if (error) throw error;
            map.current.addImage("custom-marker", image);
            /* Style layer: A style layer ties together the source and image and specifies how they are displayed on the map. */
            map.current.addLayer({
                id: "markers",
                type: "symbol",
                /* Source: A data source specifies the geographic coordinate where the image marker gets placed. */
                source: {
                    type: "geojson",
                    data: {
                        type: 'FeatureCollection',
                        features: [
                            {
                                type: 'Feature',
                                properties: {},
                                geometry: {
                                    type: "Point",
                                    coordinates: [longitude, latitude]
                                }
                            }
                        ]
                    }
                },
                layout: {
                    "icon-image": "custom-marker",
                }
            });
            map.current.scrollZoom.disable();
        });
    }, [map]);

    useEffect(() => {
        map.current.setZoom(zoom);
    }, [zoom]);

    return (
        <Section className={css.root} id={'location'}>
            <SectionHeader title={getLabel('title')} />
            <div className={css.mapControls}>
                <button className={css.controlButton} onClick={() => setZoom(Math.max(zoom - 1), 0)}>-</button>
                <button className={css.controlButton} onClick={() => setZoom(Math.min(16, zoom + 1))}>+</button>
            </div>
            <div id="map" ref={mapContainer} className={css.mapContainer} />
        </Section>
    )
}

export default LocationMap;