import { string } from 'prop-types';
import React from 'react';
import css from './Section.css';
import classNames from 'classnames';

class Section extends React.Component {
    render() {
        const { children, className, id } = this.props;
        return (
            <section className={classNames(css.root, className)} id={id}>
                {children}
            </section>
        )
    }
}

Section.propTypes = {
    className: string,
    id: string.isRequired,
};

Section.defaultProps = {
    className: null,
};

export default Section;