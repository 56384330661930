import {
    faBlender,
    faChair,
    faCoffee,
    faCut,
    faFire,
    faGlassWhiskey,
    faPlug,
    faRing, faToilet, faUtensils
} from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { useLabels } from '../../utils/translations';
import { useIntl } from 'react-intl';
import { FeatureList, Section, SectionHeader } from '../index';

const componentName = 'Equipment';
const icons = {
    extensionCord: faPlug,
    gasCanister: faFire,
    cooking: faBlender,
    frenchPress: faCoffee,
    cuttingBoard: faCut,
    tableware: faChair,
    pitcher: faGlassWhiskey,
    servingBowls: faRing,
    cutlery: faUtensils,
    toiletChemicals: faToilet
}

export default function Equipment ({ config }) {
    const intl = useIntl();
    const getLabel = useLabels("Equipment", intl);

    const features = config["equipment"]["items"].map(item => {
        return {
            label: item,
            values: config["equipment"]["values"][item],
            icon: icons[item]
        }
    });

    return (
        <Section id={'equipment'}>
            <SectionHeader title={getLabel('title')} subTitle={getLabel('subTitle')} />
            <FeatureList features={features} labelPrefix={componentName} />
        </Section>
    );
}