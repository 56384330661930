import moment from 'moment';

export const getBlockedDays = items => {
    const blockedDays = [];
    items.forEach(item => {
        const start = moment(item.start.date, "YYYY-MM-DD").startOf('day');
        const end = moment(item.end.date, "YYYY-MM-DD").add(-1, "days").endOf('day');

        const current = start.clone();
        while (current.isBefore(end)) {
            blockedDays.push(current.clone());
            current.add(1, 'day');
        }
    });

    return blockedDays;
};

export const rangeContainsBookedDay = (start, end, bookedDays) => {
    const current = start.clone().startOf('day');
    while (current.isBefore(end)) {
        if (isDayBooked(current, bookedDays)) {
            return true;
        }
        current.add(1, 'day');
    }
    return false;
};

export const loadCalendar = (calendarId) => {
    const { REACT_APP_GOOGLE_CALENDAR_API_KEY } = process.env;
    return fetch(`https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events?key=${REACT_APP_GOOGLE_CALENDAR_API_KEY}`)
        .then(response => {
            return response.json();
        });
}

export const isDayBlocked = (day, bookedDays = []) => {
    const today = moment().startOf('day');
    return day.isSameOrBefore(today, 'day') || (bookedDays.find(bookedDay => bookedDay.isSame(day, 'day')) !== undefined);
};

export const isDayBooked = (day, bookedDays = []) => {
    return bookedDays.find(bookedDay => bookedDay.isSame(day, 'day')) !== undefined;
};