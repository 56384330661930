import React, { useEffect } from "react";
import { useLabels } from '../../utils/translations';
import css from './Photos.css';
import Carousel, { Modal, ModalGateway } from 'react-images';
import { Section, SectionHeader } from '../index';
import classNames from 'classnames';
import { useIntl } from "react-intl";
import { useState } from "react";

const CYCLE_INTERVAL = 2500;

export default function Photos({ config }) {
    const [ activeIndex, setActiveIndex ] = useState(0);
    const [ activeSideScrollIndex, setActiveSideScrollIndex ] = useState(0);
    const [ modalIsOpen, setModalIsOpen ] = useState(false);
    const [ cycleInterval, setCycleInterval ] = useState(null);
    const intl = useIntl();
    const getLabel = useLabels("Photos", intl);

    const pictures = config["galleryImages"].map(item => {
        return {source: `/assets/gallery/${config["slug"]}/${item}`}
    })

    const toggleModal = (activeIndex = 0) => {
        setActiveIndex(activeIndex);
        setModalIsOpen(!modalIsOpen);
    }

    const toggleActiveThumbnail = (index) => {
        clearInterval(cycleInterval);
        setActiveSideScrollIndex(index);
    }

    useEffect(() => {
        setCycleInterval(setInterval(() => {
            const nextIndex = activeSideScrollIndex + 1;
            setActiveSideScrollIndex(nextIndex < pictures.length ? nextIndex : 0);
        }, CYCLE_INTERVAL));

        return function cleanup() {
            clearInterval(cycleInterval);
        }
    }, [activeSideScrollIndex])
    
    return (
        <Section id={'photos'}>
            <ul className={css.thumbnails}>
                {pictures.map((picture, index) => {
                    const classes = classNames(
                        css.thumbnail,
                        activeSideScrollIndex === index
                            ? css.thumbnailSideScrollActive
                            : null
                    );
                    return (
                        <li
                            className={classes}
                            key={`picture-${index}`}
                            style={{ backgroundImage: `url('${picture.source}')` }}
                            onClick={() => toggleModal(index)}
                        />
                    );
                })}
            </ul>
            <ul className={css.dots}>
                {pictures.map((picture, index) => {
                    const classes = classNames(css.dot, activeSideScrollIndex === index ? css.dotActive : null);
                    return (
                        <li
                            className={classes}
                            key={`piture-dot-${index}`}
                            onClick={() => toggleActiveThumbnail(index)}
                        />
                    );
                })}
            </ul>
            <ModalGateway>
                {modalIsOpen ? (
                    <Modal onClose={toggleModal}>
                        <div className={css.carousel}>
                            <Carousel views={pictures} currentIndex={activeIndex}/>
                        </div>
                    </Modal>
                ) : null}
            </ModalGateway>
        </Section>
    );
}

