import {
    faBacon, faBreadSlice, faBug, faBurn,
    faCarBattery, faDrumSteelpan,
    faFire,
    faHotTub, faLightbulb,
    faLock,
    faMagic, faMoon, faShower,
    faSolarPanel,
    faTint, faToilet,
    faTv, faUserShield
} from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { useLabels } from '../../utils/translations';
import { useIntl } from "react-intl";
import { FeatureList, Section, SectionHeader } from '../index';

const componentName = 'Amenities';
const icons = {
    centralAirHeating: faFire,
    solarPanels: faSolarPanel,
    boiler: faHotTub,
    battery: faCarBattery,
    waterTank: faTint,
    tv: faTv,
    alarmSystem: faLock,
    remoteControlMover: faMagic,
    refrigerator: faBacon,
    gasDetector: faBurn,
    smokeDetector: faUserShield,
    blackoutBlinds: faMoon,
    gasStove: faDrumSteelpan,
    gasOven: faBreadSlice,
    toilet: faToilet,
    shower: faShower,
    insectNets: faBug,
    lights: faLightbulb
}


export default function Amenities({ config }) {
    const intl = useIntl();
    const getLabel = useLabels(componentName, intl);

    // Map config to features
    const features = config["amenities"]["items"].map((item) => {
        return {
            label: item,
            values: config["amenities"]["values"][item],
            icon: icons[item]
        }
    });

    return (
        <Section id={'amenities'}>
            <SectionHeader title={getLabel('title')} />
            <FeatureList features={features} labelPrefix={componentName} />
        </Section>
    );
}