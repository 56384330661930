import React from "react";
import { withLabels } from '../../utils/translations';
import { Section, SectionHeader } from '../index';
import css from './ContactDetails.css';
import config from '../../config';

class ContactDetails extends React.Component {
    render() {
        const { getLabel } = this.props;
        return (
            <Section className={css.root} id={'contact'}>
                <SectionHeader title={getLabel('title')} />
                <div className={css.content}>
                    <p className={css.address}>
                        Luu OÜ<br/>
                        Hiie 3-2, Liikva küla<br/>
                        76921 Harku vald<br/>
                        Harjumaa
                    </p>
                    <p className={css.contact}>
                        <strong>{getLabel('phone')}</strong><br/>
                        +372 5358 5525<br/>
                        <strong>{getLabel('email')}</strong><br/>
                        <a href="mailto:info@tünnisellid.ee">info@tünnisellid.ee</a>
                    </p>
                    <p className={css.social}>
                        {false ? (
                        <a className={css.facebookButton} href={config.site.facebookUrl} target={'_blank'}
                           rel="noopener noreferrer">
                            {getLabel('findUsOn')}
                        </a>
                        ) : null}
                    </p>
                </div>
            </Section>
        )
    }
}

export default withLabels(ContactDetails, 'ContactDetails');