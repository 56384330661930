import { createContext, useContext, useState } from "react";
import React from "react";
import { loadCalendar, getBlockedDays } from "../utils/calendar";

const BookingContext = createContext({
    calendarIsLoaded: false,
    bookedDates: [],
    startDate: null,
    endDate: null,
    name: "",
    email: "",
    phone: "",
    message: "",
    isSending: false,
    isSent: false,
    bookingModalIsOpen: false,
    selectedOptions: [],
    setBookedDates: () => {},
    setStartDate: () => {},
    setEndDate: () => {},
    test: () => { console.log("Default test") }
});

export const BookingContextProvider = ({ children }) => {
    const [bookedDates, setBookedDates] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");
    const [isSending, setIsSending] = useState(false);
    const [isSent, setIsSent] = useState(false);
    const [bookingModalIsOpen, setBookingModalIsOpen] = useState(false);
    const [calendarIsLoaded, setCalendarIsLoaded] = useState(false);
    const [calendarIsLoading, setCalendarIsLoading] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);

    const { REACT_APP_GOOGLE_CALENDAR_ID_HOT_TUB } = process.env;

    const test = () => {
        console.log("This is a test!");
    }

    const value = {
        calendarIsLoaded,
        bookedDates,
        setBookedDates,
        startDate, 
        setStartDate,
        endDate,
        setEndDate,
        name,
        setName,
        email, 
        setEmail,
        phone,
        setPhone,
        message,
        setMessage,
        isSending,
        setIsSending,
        isSent,
        setIsSent,
        bookingModalIsOpen,
        setBookingModalIsOpen,
        test,
        selectedOptions,
        setSelectedOptions,
    }

    // Calendar
    if (!calendarIsLoaded && !calendarIsLoading) {
        setCalendarIsLoading(true);
        loadCalendar(REACT_APP_GOOGLE_CALENDAR_ID_HOT_TUB).then(data => {
            setBookedDates(getBlockedDays(data && Array.isArray(data.items) ? data.items : []));
            setCalendarIsLoaded(true);
        });
    }

    return (
        <BookingContext.Provider value={value}>
            {children}
        </BookingContext.Provider>
    )
}

export const useBookingContext = () => useContext(BookingContext);

export default BookingContext;