export default {
    site: {
        name: 'Tünnisellid',
        copyright: 'Luu OÜ',
        facebookUrl: 'https://www.facebook.com/Matkasellid-haagissuvila-rent-113529893727380',
    },
    scroll: {
        transitionTime: 400,
        topOffset: -120,
    },
    pricing: {
        perDay: 5000,
        weekend: 30000,
        cleaningFee: 3000,
        washingFee: 5000,
        deposit: 20000,
    },
    location: {
        latitude: 59.423130,
        longitude: 24.432734,
        zoomLevel: 16,
    },
    misc: {
        iconColor: '#59A96A',
    },
};
